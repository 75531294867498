// import NavScrollSpy from 'nav-scroll-spy';
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import PhotoSwipe from "photoswipe";
import PhotoSwipeUIDefault from "photoswipe/dist/photoswipe-ui-default";
import "./styles.css";

// The lang attribute is set at compile time by html-webpack-plugin
const PAGE_LANG = document.querySelector("html").lang;

/**
 * Inits the PhotoSwipe gallery
 *
 * @param {String} gallerySelector DOM selector
 */
const initPhotoSwipeFromDOM = gallerySelector => {
  // parse picture index and gallery index from URL (#&pid=1&gid=2)
  const photoswipeParseHash = () => {
    const hash = window.location.hash.substring(1);
    const params = {};

    if (hash.length < 5) {
      return params;
    }

    const vars = hash.split("&");
    for (let i = 0; i < vars.length; i += 1) {
      if (vars[i]) {
        const pair = vars[i].split("=");
        if (pair.length >= 2) {
          // eslint-disable-next-line prefer-destructuring
          params[pair[0]] = pair[1];
        }
      }
    }

    if (params.gid) {
      params.gid = parseInt(params.gid, 10);
    }

    return params;
  };

  // parse slide data (url, title, size ...) from DOM elements
  // (children of gallerySelector)
  const parseThumbnailElements = el => {
    const thumbElements = el.childNodes;
    const numNodes = thumbElements.length;
    const items = [];
    let figureEl;
    let linkEl;
    let size;
    let item;

    for (let i = 0; i < numNodes; i += 1) {
      figureEl = thumbElements[i]; // <figure> element

      // include only element nodes
      if (figureEl.nodeType === 1) {
        // eslint-disable-next-line prefer-destructuring
        linkEl = figureEl.children[0]; // <a> element

        size = linkEl.getAttribute("data-size").split("x");

        // create slide object
        item = {
          src: linkEl.getAttribute("href"),
          w: parseInt(size[0], 10),
          h: parseInt(size[1], 10)
        };

        if (figureEl.children.length > 1) {
          // <figcaption> content
          item.title = figureEl.children[1].innerHTML;
        }

        if (linkEl.children.length > 0) {
          // <img> thumbnail element, retrieving thumbnail url
          item.msrc = linkEl.children[0].getAttribute("src");
        }

        item.el = figureEl; // save link to element for getThumbBoundsFn
        items.push(item);
      }
    }

    return items;
  };

  const openPhotoSwipe = (index, galleryElement, disableAnimation, fromURL) => {
    const pswpElement = document.querySelectorAll(".pswp")[0];
    const items = parseThumbnailElements(galleryElement);
    const options = {
      // define gallery index (for URL)
      galleryUID: galleryElement.getAttribute("data-pswp-uid"),
      getThumbBoundsFn(i) {
        // See Options -> getThumbBoundsFn section of documentation for more info
        const thumbnail = items[i].el.getElementsByTagName("img")[0]; // find thumbnail
        const pageYScroll =
          window.pageYOffset || document.documentElement.scrollTop;
        const rect = thumbnail.getBoundingClientRect();

        return {
          x: rect.left,
          y: rect.top + pageYScroll,
          w: rect.width
        };
      }
    };

    // PhotoSwipe opened from URL
    if (fromURL) {
      if (options.galleryPIDs) {
        // parse real index when custom PIDs are used
        // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
        for (let j = 0; j < items.length; j += 1) {
          if (items[j].pid === index) {
            options.index = j;
            break;
          }
        }
      } else {
        // in URL indexes start from 1
        options.index = parseInt(index, 10) - 1;
      }
    } else {
      options.index = parseInt(index, 10);
    }

    // exit if index not found
    if (Number.isNaN(options.index)) {
      return;
    }

    if (disableAnimation) {
      options.showAnimationDuration = 0;
    }

    // Pass data to PhotoSwipe and initialize it
    const gallery = new PhotoSwipe(
      pswpElement,
      PhotoSwipeUIDefault,
      items,
      options
    );
    gallery.init();
  };

  // find nearest parent element
  const closest = function closest(el, fn) {
    return el && (fn(el) ? el : closest(el.parentNode, fn));
  };

  // triggers when user clicks on thumbnail
  const onThumbnailsClick = e => {
    const event = e || window.event;
    if (event.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
    }

    const eTarget = e.target || e.srcElement;

    // find root element of slide
    const clickedListItem = closest(
      eTarget,
      el => el.tagName && el.tagName.toUpperCase() === "FIGURE"
    );

    if (!clickedListItem) {
      return;
    }

    // find index of clicked item by looping through all child nodes
    // alternatively, you may define index via data- attribute
    const clickedGallery = clickedListItem.parentNode;
    const { childNodes } = clickedListItem.parentNode;
    const numChildNodes = childNodes.length;
    let nodeIndex = 0;
    let index;

    for (let i = 0; i < numChildNodes; i += 1) {
      if (childNodes[i].nodeType === 1) {
        if (childNodes[i] === clickedListItem) {
          index = nodeIndex;
          break;
        }
        nodeIndex += 1;
      }
    }

    if (index >= 0) {
      // open PhotoSwipe if valid index found
      openPhotoSwipe(index, clickedGallery);
    }
  };

  // loop through all gallery elements and bind events
  const galleryElements = document.querySelectorAll(gallerySelector);

  for (let i = 0, l = galleryElements.length; i < l; i += 1) {
    galleryElements[i].setAttribute("data-pswp-uid", i + 1);
    galleryElements[i].onclick = onThumbnailsClick;
  }

  // Parse URL and open gallery if it contains #&pid=3&gid=1
  const hashData = photoswipeParseHash();
  if (hashData.pid && hashData.gid) {
    openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
  }
};

const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ""}${expires}; path=/`;
};

const getCookie = name => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

// const eraseCookie = (name) => {
//   document.cookie = `${name}=; Max-Age=-99999999;`;
// }

// Remember preferred lang onclick lang-switch
document.querySelector("#lang-switch").onclick = e => {
  e.preventDefault();

  setCookie("lang", PAGE_LANG === "en" ? "es" : "en");
  window.location = e.target.href;
};

/**
 * Returns true if browser language is "es"
 *
 * navigator.language(Netscape - Browser Localization)
 * navigator.browserLanguage(IE - Specific - Browser Localized Language)
 */
const isEsBrowser = () => {
  const browserLang = navigator.language || navigator.browserLanguage;

  return browserLang.startsWith("es");
};

window.onload = () => {
  const preferredLang = getCookie("lang");

  // view in English unless browser is "es"
  if (!preferredLang && !isEsBrowser() && !PAGE_LANG.startsWith("en")) {
    // preferred lang
    setCookie("lang", "en");

    // Redirect to view in English
    window.location = "/en";
  } else {
    // Init photogallery
    initPhotoSwipeFromDOM("#gallery");
  }
};

// {
//   "Version": "2008-10-17",
//     "Id": "PolicyForCloudFrontPrivateContent",
//       "Statement": [
//         {
//           "Sid": "1",
//           "Effect": "Allow",
//           "Principal": {
//             "AWS": "arn:aws:iam::cloudfront:user/CloudFront Origin Access Identity E1ULU748FF5AJO"
//           },
//           "Action": "s3:GetObject",
//           "Resource": "arn:aws:s3:::www.gosport.com.py/*"
//         }
//       ]
// }
